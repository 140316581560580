import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SubHero from "../components/ui/subHero"
import { Wrapper, Section } from "../components/container"

const Imprint = () => (
  <Layout>
    <Seo title="Impressum" />
    <SubHero Headline="Impressum" />
    <Section>
      <Wrapper>
        <h2 className="text-lg text-primary mb-3 mt-12">
          Angaben gem&auml;&szlig; &sect; 5 TMG
        </h2>
        <p>
          Nadine Amthor
          <br />
          Ma&szlig;atelier Tailor&#039;s Nook
          <br />
          Moislinger Allee 22a
          <br />
          23558 L&uuml;beck
        </p>

        <h2 className="text-lg text-primary mb-3 mt-12">Kontakt</h2>
        <p>Telefon: 01573 7530267</p>
        <a
          href="mailto:mail@tailorsnook.de"
          className="hover:text-secondary transition duration-200 ease-in-out"
        >
          {" "}
          E-Mail: mail@tailorsnook.de
        </a>

        <h2 className="text-lg text-primary mb-3 mt-12">Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
          Umsatzsteuergesetz:
          <br />
          DE345831440
        </p>

        <h2 className="text-lg text-primary mb-3 mt-12">
          Angaben zur Berufs&shy;haftpflicht&shy;versicherung
        </h2>
        <p>
          <strong>Name und Sitz des Versicherers:</strong>
          <br />
          Generali Deutschland Versicherung AG
          <br />
          20081 Hamburg
        </p>
        <p>
          <strong>Geltungsraum der Versicherung:</strong>
          <br />
          Deutschland
        </p>

        <h2 className="text-lg text-primary mb-3 mt-12">
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <p>
          Quelle:{" "}
          <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
        </p>
      </Wrapper>
    </Section>
  </Layout>
)

export default Imprint
